.transaction-popup{
    min-height: 500px;
    padding: 15px;
}
.form-title{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
    font-size: 16px;
    font-weight: bold;
}
.input-row-group{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
.input-row-group .separator{
    min-width: 50px;
    height: 38px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}
.input-row-group .separator i{
    color: #d2d2d2;
}