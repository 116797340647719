.options-list{
    width: 100%;
    max-height: 200px;
    height: 0;
    overflow-y: scroll;
    overflow-x: hidden;
    border-radius: 5px;
    position: absolute;
    top: 45px;
    z-index: 100;
}
.options-list.opened{
    height: auto;
    border: 1px solid #CCCCCC;
}
.options-list .list-content{
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
}
.options-list .list-content .option-item{
    width: 100%;
    display: flex;
    padding: 5px 10px;
    border-bottom: 1px solid #CCCCCC;
    cursor: pointer;
}
.options-list .list-content .option-item.active{
    background-color: #f1f1f1;
}
.options-list .list-content .option-item:last-child{
    border-bottom: none;
}