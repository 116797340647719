.legend-block{
    display: flex;
    flex-direction: column;
    width: 200px;
    background: rgba(0,0,0,0.7);
}
.legend-block .title{
    font-size: 16px;
    color: #ffffff;
    width: 100%;
    display: flex;
    padding: 5px 15px;
}
.legend-block .legend{
    display: flex;
    padding: 2px 10px;
    color: #ffffff;
    font-size: 14px;
}
.dashboard-filters{
    border-bottom: none!important;
    margin-top: 25px;
}