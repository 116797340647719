.cost-popup{
    min-height: 500px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.form-title{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
    font-size: 16px;
    font-weight: bold;
}
.add-transaction{
    cursor: pointer;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    border: 1px solid #cccccc;
    border-radius: 50%;
    line-height: 1;
    padding: 0;
    padding-bottom: 4px;
}
.back-button{
    cursor: pointer;
    font-size: 14px;
}
.back-button:hover{
    text-decoration: underline;
}
.action-buttons i{
    margin-right: 10px;
    cursor: pointer;
}
.action-buttons i:last-child{
    margin-right: 0;
}
.total-info{
    display: flex;
}
.total-info .total-item{
    padding: 5px 15px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 5px;
}
.total-info .total-item.green{
    background-color: green;
}
.total-info .total-item.red{
    background-color: red;
}