body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.scrollbar {
  height: 100%;
  width: 65px;
  background: #F5F5F5;
  overflow-y: scroll;
  margin-bottom: 25px;
}

.scrollbar.scrollbar-style-1::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 2px rgba(0,0,0,0);
  background-color: #F5F5F5;
}

.scrollbar.scrollbar-style-1::-webkit-scrollbar
{
  width: 3px;
  background-color: #F5F5F5;
}

.scrollbar.scrollbar-style-1::-webkit-scrollbar-thumb
{
  background-color: #3c3c3c;
  border: none;
}

.custom-input-block{
  width: 100%;
  min-height: 40px;
  margin-bottom: 25px;
  position: relative;
}
.custom-input-block .custom-label{
  margin-left: 15px;
  background-color: #ffffff;
  padding: 0 5px;
  position: absolute;
  top: -10px;
  font-size: 12px;
  z-index: 10;
}
.custom-input-block .custom-error{
  position: absolute;
  bottom: -15px;
  right: 0;
  font-size: 12px;
  color: #ff0000;
}

.custom-input-block .rs-picker-tree{
  border: none;
}
.custom-input-block .form-control{
  color: #1676e0;
}
.custom-input-block.error .form-control{
  border: 1px solid #ff0000;
}
.custom-input-block .form-control:focus{
  box-shadow: none;
}
.rs-picker-menu{
  z-index: 1000!important;
}
.rs-picker-menu{
  opacity: 1!important;
}

.close-popup {
  position: absolute;
  right: -15px;
  top: -15px;
  width: 30px;
  height: 30px;
  background: #ffffff;
  border-radius: 50%;
  border: 1px solid #545454;
  cursor: pointer;
}
.close-popup:hover {
  opacity: 1;
}
.close-popup:before, .close-popup:after {
  position: absolute;
  left: 13px;
  content: ' ';
  height: 13px;
  width: 2px;
  background-color: #333;
  top: 8px;
}
.close-popup:before {
  transform: rotate(45deg);
}
.close-popup:after {
  transform: rotate(-45deg);
}
.my-popup-content{
  max-height: 90vh;
  overflow-y: scroll;
}
.my-scroll{
  overflow-y: scroll;
}
.my-scroll::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 2px rgba(0,0,0,0);
  background-color: #ffffff;
}
.my-scroll::-webkit-scrollbar
{
  width: 3px;
  background-color: #F5F5F5;
}
.my-scroll::-webkit-scrollbar-thumb
{
  background-color: #757575;
  border: 0 solid #ffffff;
  border-radius: 2px;
}
.c-pointer{
  cursor: pointer;
}
.rs-tooltip{
  opacity: 1!important;
}

.my-scrollbar {
  overflow-y: scroll;
  overflow-x: hidden;
}
.my-scrollbar::-webkit-scrollbar-track{
  background-color: #ffffff;
}
.my-scrollbar::-webkit-scrollbar{
  width: 3px;
}
.my-scrollbar::-webkit-scrollbar-thumb{
  background-color: #6b6b6b;
}
.rs-picker-select-menu,
.rs-picker-date-menu,
.rs-picker-tree-menu{
  z-index: 9999!important;
  opacity: 1!important;
}