.sidebar {
    padding: 0 0 55px 0;
}
.sidebar .sidebar-content{
    width: 100%;
}
.sidebar .sidebar-content a{
    width: 100%;
    height: 45px;
    display: flex;
    padding: 0 10px;
    align-items: center;
    color: #ffffff;
    text-decoration: none;
    border-bottom: 1px solid #4a4a4a;
}
.sidebar .sidebar-content a.active{
    background-color: #4a4a4a;
}