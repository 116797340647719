.filters{
    padding-top: 5px;
    border-bottom: 1px solid #d8d8d8;
    margin-bottom: 15px;
    padding-bottom: 15px;
}
.filters label{
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 3px;
    padding-left: 5px;
}
.rs-message-body,
.rs-message-header{
    text-align: center;
}
.rs-modal-body{
    padding-bottom: 0!important;
}
.account-info{
    display: flex;
    flex-direction: column;
}
.account-info .amount{
    font-weight: bold;
}
.account-info .details{
    cursor: pointer;
}
.account-info .details:hover{
    color: #000000;
}
.account-list .account-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 25px;
}

.account-list .account-item div{
    width: 100%;
    display: flex;
    justify-content: center;
    font-weight: bold;
}
.add-buttons{
    position: fixed;
    right: 50px;
    bottom: 15px;
    display: flex;
    z-index: 10;
}
.add-buttons .add-item{
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    background-color: #ffffff;
    border-radius: 50%;
    box-shadow: 0 0 15px #7b7b7b;
    cursor: pointer;
}
.add-buttons .add-item:last-child{
    margin-right: 0;
}
.back-block{
    cursor: pointer;
}
.back-block:hover{
    opacity: 0.7;
}
.back-block i{
    margin-right: 5px;
}
.modal-form{
    max-height: calc(100vh - 215px);
}