.login-page{
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.login-page .login-form{
    width: 600px;
    height: 600px;
    /*display: flex;*/
    padding: 10px 25px;
}