.authorized{
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
}
.authorized .header{
    width: 100vw;
    height: 45px;
    background-color: #282c34;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 25px;
}
.authorized .page-block{
    display: flex;
    width: 100vw;
    min-height: calc(100vh - 45px);
}
.authorized .page-block .sidebar{
    display: inline-flex;
    width: 225px;
    background-color: #282c34;
    border-top: 1px solid #4a4a4a;
}
.authorized .page-block .page-content{
    display: inline-flex;
    flex-grow: 1;
    padding: 10px;
    align-items: flex-start;
    justify-content: flex-start;
}